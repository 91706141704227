import React from 'react'
import { useState } from 'react'
import "./OurStudios.css"
import BurCityLocation from './BurCityLocation/BurCityLocation'
import EmiratesLocation from './EmiratesLocation/EmiratesLocation'
import MotorCityLocation from './MotorCityLocation/MotorCityLocation'
import AlQuasis from './AlQuasis/AlQuasis'
import DubaiSiliconPlaza from './DubaiSiliconPlaza/DubaiSiliconPlaza'
import { Tab, Tabs } from '@mui/material'
import SwipeableViews from 'react-swipeable-views'



const OurStudios = () => {

    const [toggletab,setToggleTab] = useState(0)

    const handleToggle = (index) => {
        setIndex(index)
    }

    const [index, setIndex] = useState(0);

    const handleChange = (e,value) => {
        setIndex(value)
    }

  return (
    <div className='OurStudios'>
        <div className="ourStudiosContainer">
            <h2 className='ourStudiosWeb'>Our Studios</h2>
            <h2 className='outStudiosResp'>Our Studios (5)</h2>
        </div>

        <div className="ourStudiosParent">
        {/* <div className="ourStudiosTabContainer" >
            <div className={index == 0 ? "ourStudiosTab activeOurStudiosTab" : "ourStudiosTab"} onClick={()=>handleToggle(0)}>
            Bur Dubai
             
            </div>
            <div className={index == 1 ? "ourStudiosTab activeOurStudiosTab" : "ourStudiosTab"} onClick={()=>handleToggle(1)}>
            Motor City
            </div>
            <div className={index == 2 ? "ourStudiosTab activeOurStudiosTab" : "ourStudiosTab"} onClick={()=>handleToggle(2)}>
            Emirates Golf Club
            </div>
            <div className={index == 3 ? "ourStudiosTab activeOurStudiosTab" : "ourStudiosTab"} onClick={()=>handleToggle(3)}>
            AI Qusais
            </div>
            <div className={index == 4 ? "ourStudiosTab activeOurStudiosTab" : "ourStudiosTab"} onClick={()=>handleToggle(4)}>
            Dubai Silicon Oasis
            </div>
        </div> */}

        <Tabs value={index} onChange={handleChange}
            TabIndicatorProps={{
                sx:{
                    background:"#FE5266",
                    height:"5px"
                }
            }}
            sx={{
                "& button":{marginRight:"10px",fontFamily:"Avenir LT Std"},
                "& button.Mui-selected":{fontWeight:"750",color:"#FE5266"}
            }}
        >
          <Tab  label="Bur Dubai" />
          <Tab label="Motor City" />
          <Tab  label="Emirates Golf Club" />
          <Tab  label= "AI Qusais" />
          <Tab label="Dubai Silicon Oasis" />
        </Tabs>

        

        </div>
        {/* <div className="ourStudiosTabContent">

            {toggletab == 0 && <BurCityLocation/>}

            {toggletab == 1 && <MotorCityLocation/>}

            {toggletab == 2 && <EmiratesLocation/>}

            {toggletab == 3 && <AlQuasis/>}

            {toggletab == 4 && <DubaiSiliconPlaza/>}
        </div> */}
    <div className="ourStudiosTabContent">
        <SwipeableViews index={index} enableMouseEvents onChangeIndex={(index) => setIndex(index)}>
            <BurCityLocation/>
            <MotorCityLocation/>
            <EmiratesLocation/>
            <AlQuasis/>
            <DubaiSiliconPlaza/>
        </SwipeableViews>
        </div> 
    </div>
  )
}

export default OurStudios