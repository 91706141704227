// @ts-nocheck
import React from "react";
// import Countdown, { zeroPad } from "react-countdown";
import JoinAdd from "../JoinAdd/JoinAdd";
import "./Advertisement.css";
export default function Advertisement({ type, modalHandler }) {
	function diffMinutes(date1, date2) {
		const d1 = new Date(date1).getTime();
		const d2 = new Date(date2).getTime();
		return Math.abs(Math.round(d2 - d1)); // Can use Math.floor or Math.ceil depends up to you
	}
	const diff = () => {
		const current = new Date();
		const future = new Date(process.env.REACT_APP_FUTURE_DATE);
		return diffMinutes(future, current);
	};

	const ad1 = () => {
		return (
			<>
				<div>
					<h2 className='title'>
						No Two Classes are the{" "}
						<span className='pink'>SAME!</span>
					</h2>
					<p className='brief my-3'>
						Every Session Surprises The Body, Activates New Muscle
						And is Uniquely Designed to Give Maximum Results.
					</p>
				</div>
				
				<div className='joinAddBtn'>
					<JoinAdd modalHandler={modalHandler} />
				</div>
			</>
		);
	};
	const ad2 = () => {
		return (
			<>
				<div>
					<h2 className='title'>
						Just Burning Calories is{" "}
						<span className='pink'>Not Enough</span>
					</h2>
					<p className='brief my-3'>
					With Artistic Yoga, you improve flexibility, gain strength, harmonize your internal organs and breathe correctly, literally recharging your whole system.
					</p>
				</div>
				
				<div className='joinAddBtn'>
					<JoinAdd modalHandler={modalHandler} />
				</div>
			</>
		);
	};
	const ad3 = () => {
		return (
			<>
				<div className='w-100'>
					<h2 className='title'>
						Our Teachers are A{" "}
						<span className='pink'>Breed Apart</span>
					</h2>
					<p className='brief my-3'>
					Our teachers are personally trained by Dr. Bharat Thakur for years in the deepest aspects of yoga as well as a complete understanding of the human body and its various systems.
					</p>
				</div>
				
				<div className='joinAddBtn'>
					<JoinAdd modalHandler={modalHandler} />
				</div>
			</>
		);
	};

	const ad4 = () => {
		return (
			<>
				<div className='w-100'>
					<h2 className='title'>
						Not able to attend our{" "}<br/>
						<span className='pink'>Studio classes?</span>
					</h2>
					<p className='brief my-3'>
					We also have Online Zoom sessions and personal one on one sessions at your home (in Dubai only).
					</p>
				</div>
				
				<div className='joinAddBtn'>
					<JoinAdd modalHandler={modalHandler} />
				</div>
			</>
		);
	};
	return (
		<div className='advertisementWrapper px-3'>
			<div className='Advertisement p-sm-5 p-sm-2 p-4'>
				<div className='row row-cols-1 row-cols-sm-2 g-3'>
					<div className='col-12 col-sm-7 d-flex align-items-center'>
						<div className='w-100 h-100 d-flex flex-column justify-content-center'>
							{type == 1 && ad1()}
							{type == 2 && ad2()}
							{type == 3 && ad3()}
							{type == 4 && ad4()}
						</div>
					</div>
					<div className=' col-12 col-sm-5 d-flex flex-column align-items-center justify-content-center'>
						<div className='w-100'>
							<div className='specialOfferBanner'>
								<img
									src='assets/offerIcon.svg'
									alt=''
									className='img-fluid d-none d-sm-inline-block'
									style={{width:"auto",height:"302px"}} 
									/>

								<img
									src='assets/offerIcon.svg'
									alt=''
									width= "132px"
								height=" 101px"
								className='img-fluid  d-sm-none d-inline-block'
								/>
							</div>
							
						</div>
						{/* <div className="OfferNeverBefore d-md-none d-sm-block joinAd flex-column">
							<div >
								Never Before Offer
							</div>
						</div> */}
						<div className='mt-4 d-sm-none d-block  my-sm-4'>
							<JoinAdd modalHandler={modalHandler} />
						</div>

						{/* <div className='d-sm-none d-block'>
							<div className='remain d-flex align-items-center justify-content-center py-4'>
								Remaining Until
							</div>
							
						</div> */}
					</div>
				</div>
			</div>
		</div>
	);
}
