import React from 'react'
import "./specialOffers.css"

const SpecialOffers = () => {
  return (
    <div className='SpecialOffers'>
        <div className="specialOfferHeader">
            <h2>Special Offer</h2>
            <p>Offer ends at 31st March. Purchase now and Start anytime!</p>

            <div className="offersContainer">
                <div className="offers">
                3 Month Package + Get 1 Month Free*
                </div>

                <div className="offers offers2">
                6 Month Package + Get 2 Month Free*
                </div>

                <div className="offers">
                1 Year Package + Get 3 Month Free*
                </div>
            </div>
        </div>
    </div>
  )
}

export default SpecialOffers