import React from 'react'
import "./homeBanner.css"

const HomeBanner = () => {
  return (
    <div className='HomeBanner'>
        
        <div className="yogaClassDescription">
            <div className="studioSession">
                <h3>STUDIO SESSIONS</h3>
            </div>
        
            <h2>Looking For A <span>Yoga Class <br/> </span> That Takes Care of <span>All Your Needs?</span></h2>
            <p className='para'>
            Developed by the living Himalayan master Dr Bharat Thakur, Artistic Yoga combines the best of modern workout techniques and powerful yogic practices to increase your metabolism working on the deepest layers of muscles. The result? A thinner, fitter, calmer and more energetic you!
            </p>

            <div className="studioImgResp">
                <img src="/assets/homebanner2.png" alt="" />
            </div>

            <div className='benefitsContainerParent'>

            <div className="benefitsContainer">
                <div className="benefits">
                    <img src="assets/focusedProgram.svg" alt="" />
                    <div className="benefitsPara">
                        <h4>FOCUSED PROGRAMS</h4>
                        <span>
                        Like Reduce Every Day, SuperFAST, Classic, RAPID, Meditation & Breathing.
                        </span>
                    </div>
                </div>

                <div className="benefits">
                    <img src="assets/fantasticTeacher.svg" alt="" />
                    <div className="benefitsPara">
                        <h4>FANTASTIC TEACHERS</h4>
                        <span>
                        With years (and decades) of experience and wisdom.
                        </span>
                    </div>
                </div>

            </div>

            <div className="benefitsContainer">

                <div className="benefits">
                    <img src="assets/results.svg" alt="" />
                    <div className="benefitsPara">
                        <h4>AMAZING RESULTS</h4>
                        <span>
                        That we have given to our clients for the last 20 years.
                        </span>
                    </div>
                </div>

                <div className="benefits">
                    <img src="assets/gift.svg" alt="" />
                    <div className="benefitsPara">
                        <h4>GREAT PACKAGES</h4>
                        <span>
                        To suit every budget.
                        </span>
                    </div>
                </div>

            </div>



            <div className="benefitsContainer">
                <div className="benefits">
                    <img src="assets/convenientLocation.svg" alt="" />
                    <div className="benefitsPara">
                        <h4>CONVENIENT LOCATIONS</h4>
                        <span>
                        Near your home with FREE PARKING & SHOWER FACILITIES.
                        </span>
                    </div>
                </div>

                <div className="benefits">
                    <img src="assets/convenientTime.svg" alt="" />
                    <div className="benefitsPara">
                        <h4>CONVENIENT TIMINGS</h4>
                        <span>
                        We have Morning and Evening Sessions at Our Studios.
                        </span>
                    </div>
                </div>

            </div>

        </div>

        </div>
        <div className="studioImg">
            <img src="/assets/homebanner2.png" alt="" />
        </div>
    </div>
  )
}

export default HomeBanner