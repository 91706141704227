import React from "react";
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from './Components/HomePage/HomePage';
import PrivacyPage from "./Components/PrivacyPage/PrivacyPage";
import TermsConditionPage from "./Components/TermsConditionPage/TermsConditionPage";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ThankyouPage from "./Components/ThankyouPage/ThankyouPage";


const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);


function App() {



  return (
   
    
      <Switch >
        <Route exact path='/'><HomePage /></Route>
        <Route exact path='/ThankyouPage'><ThankyouPage /></Route>
        <Route exact path='/privacy-policy'><PrivacyPage /></Route>
		<Route exact path='/terms-conditions'><TermsConditionPage /></Route>
      </Switch>
   
    
  );
}

export default App;
