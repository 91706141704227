import React, { useState } from "react";
import "./DownloadEbook.css";
import DownloadModal from "./DownloadModal/DownloadModal";
import fileDownload from "js-file-download";
import axios from "axios";
import FileSaver from "file-saver";
import PhoneInput from "react-phone-input-2";
import useGeoLocation from "react-ipgeolocation";
import { Button } from "react-bootstrap";
export default function DownloadEbook() {
    const [error, setError] = useState('')
    const location = useGeoLocation();
    const [btnText, setbtntext] = React.useState("Get your E-book");

    const [isDisable, setIsDisable] = useState(true)
    const [EbookDownload, setEbookDownload] = React.useState({
        name: "",
        emailId: "",
        mobile: "",
        isd: '',
    });

    function download_file(fileURL, fileName) {
        // for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement("a");
            save.href = fileURL;
            save.target = "_blank";
            var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
            save.download = fileName || filename;
            if (
                navigator.userAgent
                    .toLowerCase()
                    .match(/(ipad|iphone|safari)/) &&
                navigator.userAgent.search("Chrome") < 0
            ) {

                document.location = save.href;
            } else {
                var evt = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: false,
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }
        }
        // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
            var _window = window.open(fileURL, "_blank");
            _window.document.close();
            _window.document.execCommand("SaveAs", true, fileName || fileURL);
            _window.close();
        }
    }
    const downloadEffect = () => {
        if (EbookDownload.name && EbookDownload.emailId && EbookDownload.mobile && EbookDownload.isd) {

            const submitFormData = {
				...EbookDownload,originCampaign:process.env.REACT_APP_ORIGIN_CAMPAIGN
			}
			const NewFormData =  JSON.stringify(submitFormData)


            axios
                .post(
                    process.env.REACT_APP_EBOOK_URL,
                   NewFormData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    console.log(res.data);
                    if (res.data !== "failed") {
                        if (res.data.status_text != "200") {
                            setError(res.data.status_text)
                        }

                    }
                    if (res.data != null) {
                        download_file(res.data.ebookURL, "FreeRed.pdf");
                        document.getElementById("Name").setAttribute("readOnly", "true")
                        document.getElementById("Email").setAttribute("readOnly", "true")
                        document.getElementById("Mobile").setAttribute("readOnly", "true")
                        setbtntext('Download')
                    }
                })
                .catch((err) => console.log(err));
        }


    };
    const handleChange = (value, data, event, formattedValue) => {
        const mobile = value.slice(data.dialCode.length)
        setEbookDownload((prev) => ({ ...prev, mobile, isd: data.dialCode}))

    };
    if (EbookDownload) {
        if (EbookDownload.name && EbookDownload.emailId && EbookDownload.mobile.length >= 5 && EbookDownload.isd) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(EbookDownload.emailId)) {
                document.getElementById("GetbookId").style.opacity = "1"


            }
        }
    }

    return (
        <div className="d-flex justify-content-center">
            <div className='DownloadEbook'>
                <div>
                    <p>
                        <img src="assets/FreeE-Book.svg" alt="" />

                    </p>
                    <p className="EbookDescription">
                        Still not sure? While you decide, we would like to offer you a free ebook - <b>SNACK AND LOSE WEIGHT!</b> 30 guilt-free recipes for healthy living.

                    </p>
                </div>
                <div className='row   '>
                    <div className="  EbookForm col-sm-12  col-md-12 col-lg-6 ">
                        <div className='d-flex flex-column mt-3 joinNow'>
                            <label htmlFor='Name'>Name</label>
                            <input
                                placeholder='Enter your name'
                                type='text'
                                id='Name'
                                name='name'
                                onChange={(e) => setEbookDownload((prev) => {
                                    return { ...prev, name: e.target.value }
                                })}
                            />
                        </div>
                        <div className='d-flex flex-column mt-3 joinNow'>
                            <label htmlFor='Email'>Email Address</label>
                            <input
                                placeholder='Enter your email'
                                type='email'
                                name='emailId'

                                onChange={(e) => setEbookDownload((prev) => {
                                    return { ...prev, emailId: e.target.value }
                                })}
                                className='EmailInps'
                                id='Email'
                            />
                        </div>
                        <div className='d-flex flex-column mt-3 joinNow'>
                            <label htmlFor="email">Phone Number</label>
                            <PhoneInput
                                // @ts-ignore
                                className="joinNowinput"
                                placeholder='Enter your mobile'
                                type='text'

                                inputProps={{
                                    name: 'mobile',
                                    required: true,
                                    id: 'Mobile'
                                }}
                                onChange={handleChange}

                                country={location?.country?.toLowerCase()}
                            />

                        </div>
                        <div className="GetBookWrapper">
                            <div
                                id="GetbookId"
                                style={{ cursor: "pointer" }}
                                onClick={() => downloadEffect()}
                                className={`disable joinToday GetBook`}>
                                {btnText}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center pdfImage col-sm-12 col-md-12 col-lg-6  order-first order-sm-first order-md-first order-lg-last" >
                        <img className="img-fluid" width="100%" src='assets/pdf2.png' alt="" />
                    </div>
                </div>

            </div>
        </div>
    );
}