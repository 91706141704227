import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './ThankYouPopUp.css'
const ThankYouPopUp = ({ ThankYouPopUpHide, ShowThanksPopUp }) => {

    const history = useHistory();

    const handleNavigateHomePage = () => {
        history.push("/")
    }

    return (
        // <Modal dialogClassName='modal-60w ' centered show={ShowThanksPopUp} onHide={ThankYouPopUpHide}>
        //     <div className='ThankYouWrapper'>
        //         <Modal.Header closeButton onClick={handleNavigateHomePage}>
        //         </Modal.Header>
        //         <Modal.Body className="py-4">
        //             <div className="d-flex justify-content-center">
        //                 <div>
        //                     <div className='d-flex justify-content-center'>
        //                         <img src="assets/Hands.svg" alt="" />
        //                     </div>
        //                     <div >
        //                         <p className='text-center ThankYouTittle'>Thank You</p>
        //                         <p className='text-center ThankYouDescription'>
        //                             One of us will connect
        //                             with you at the earliest
        //                         </p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </Modal.Body>
        //     </div>

        // </Modal>

        <div className="d-flex justify-content-center align-item-center vh-100">
            <div className='d-flex justify-content-center align-item-center flex-column'>
                <div className='d-flex justify-content-center align-item-center'>
                    <img src="assets/Hands.svg" alt="" />
                </div>
                <div>
                    <p className='text-center ThankYouTittle'>Thank You</p>
                    <p className='text-center ThankYouDescription'>
                        One of us will connect
                        with you at the earliest
                    </p>
                </div>
            </div>
        </div>

    );
};

export default ThankYouPopUp;