import React from 'react'
import Nav from "../Nav/Nav";
import JoinNowModal from "../JoinNowModal/JoinNowModal";
import RedBanner from "../RedBanner/RedBanner";
import ClientsReview from "../ClientsReview/ClientsReview";
import Advertisement from "../Advertisement/Advertisement";
import Benefits from "../Benefits/Benefits";
import WhyRed from "../WhyRed/WhyRed";
import HourDayComparism from "../HourDayComparism/HourDayComparism";
import OurTeachers from "../OurTeachers/OurTeachers";
import AboutBharatThakur from "../AboutBharatThakur/AboutBharatThakur";
import FAQ from "../FAQ/FAQ";
import DownloadEbooknew from "../DownloadEbook/DownloadEbooknew";
import Footer from "../Footer/Footer";
import HomeBanner from '../HomeBanner/HomeBanner';
import StudioPrograms from '../StudioPrograms/StudioPrograms';
import OurStudios from '../OurStudios/OurStudios';
import SpecialOffers from '../SpecialOffers/SpecialOffers';
import ThankYouPopUp from "../ThankYouPopUp/ThankYouPopUp";




const HomePage = () => {

    const [modalShow, setModalShow] = React.useState(false);
    const [ShowThanksPopUp, setShowThanksPopUp] = React.useState(false);

    const ThankYouPopUpHide = () => setShowThanksPopUp(false);
    const ThankYouPopUpShow = () => setShowThanksPopUp(true);

  return (
    <>
       <JoinNowModal ThankYouPopUpShow={ThankYouPopUpShow} show={modalShow} onHide={() => setModalShow(false)} />
       {/* <ThankYouPopUp ThankYouPopUpHide={ThankYouPopUpHide} ShowThanksPopUp={ShowThanksPopUp} /> */}
			<div id='home'>
				<Nav modalHandler={setModalShow} />
				<div className='px-3 px-sm-5'>
                    <HomeBanner/>
                    <RedBanner modalHandler={setModalShow} />
                    <StudioPrograms/>
                    <ClientsReview/>
                    <Benefits />
					          <Advertisement modalHandler={setModalShow} type={1} />
                    <WhyRed />
                    <Advertisement modalHandler={setModalShow} type={2} />
                    <HourDayComparism />
                    <OurTeachers />
                    <Advertisement modalHandler={setModalShow} type={3} />
                    <SpecialOffers/>
                    <AboutBharatThakur />
                    <OurStudios/>
                    <FAQ />
                    <Advertisement modalHandler={setModalShow} type={4} />
                </div>
                <DownloadEbooknew/>
				        <Footer />
            </div>
    </>
  )
}

export default HomePage